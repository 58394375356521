<!-- <app-sidebar></app-sidebar> -->
<div id="admin-layout">
  <app-navbar></app-navbar>
  <main
    id="wrapper-content"
    role="main"
    class="container animate__animated animate__fadeInDown"
  >
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
