<nav class="navbar navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" routerLink="/"> وزارة الصناعة و المعادن </a>
    <div class="row">
      <div class="col text-left mx-4">
        <div class="d-inline-block px-1">
          <img src="/assets/icons/facebook-brands.svg" width="25px" alt="" />
        </div>
        <div class="d-inline-block px-1">
          <img src="/assets/icons/youtube-brands.svg" width="30px" alt="" />
        </div>
        <div class="d-inline-block px-1">
          <img
            src="/assets/icons/twitter-square-brands.svg"
            width="20px"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</nav>
