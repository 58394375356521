<!--The content below is only a placeholder and can be replaced.-->
<!-- <div
  *ngIf="loading"
  id="routing-loader"
  class="d-flex justify-content-center align-items-center"
>
  <div id="routing-loader__logo">
    <img src="/assets/images/logo.png" width="100" />
  </div>
</div> -->

<router-outlet></router-outlet>
