import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layout/admin-layout/admin-layout.component";

const adminRoutes = [
  {
    path: "trademarks",
    loadChildren: () =>
      import("./view/trademarks/trademarks.module").then(
        (m) => m.TrademarksModule
      ),
  },
  {
    path: "engineering-examination",
    loadChildren: () =>
      import(
        "./view/engineering-examination/engineering-examination.module"
      ).then((m) => m.EngineeringExaminationModule),
  },
];

const routes: Routes = [
  // {
  //   path: "",
  //   component: AuthLayoutComponent,
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: "./view/accounts/accounts.module#AccountsModule",
  //     },
  //   ],
  // },
  {
    path: "",
    loadChildren: () =>
      import("./view/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "",
    component: AdminLayoutComponent,
    // canActivate: [AuthGuard],
    children: adminRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
