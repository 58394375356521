import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedComponentsModule } from "./components/shared-components.module";
import { BInputComponent } from "./components/b-input/b-input.component";
import { FormsModule } from "@angular/forms";
import { BDatepickerComponent } from "./components/b-datepicker/b-datepicker.component";
import { BAttachmentComponent } from "./components/b-attachment/b-attachment.component";

@NgModule({
  declarations: [BInputComponent, BDatepickerComponent, BAttachmentComponent],
  imports: [SharedComponentsModule, CommonModule, FormsModule],
  exports: [BInputComponent, BDatepickerComponent, BAttachmentComponent],
})
export class SharedModule {}
